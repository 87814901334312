import {
  plus,
  minus,
  mod,
  loadScript,
} from './scripts/_index';

const item = (idx) => {
  const test = {
    one: 1,
    two: 2,
    four: 4,
  };

  // the ??= is a combination of an assignment a null coalescing operator.
  test.three ??= 3;
  // if test.three is undefined, it is set to be equal to 3.
  // analagous to 'test.three = test.three ? test.three : 3'
  return minus(
    parseInt(test[idx], 10),
    plus(parseInt(test.one, 10), parseInt(test.two, 10)),
  );
};

// uncommenting the next line will intentionally trigger a linting error.
// item = 'test';

// console.log('test', item('four'));
// console.log('test1', minus(35, 10));
// console.log('test1', plus(-10135, 1911));
// console.log('test mod: ', mod(25, 5));

const modal = {
  init: () => {
    document.addEventListener('modalScriptLoaded', modal.callback);
  },
  callback: () => {
    /*
     * We know that the modal scripts are ready to use, so let's do
     * whatever we need to to get them started.
     */
  },
  required: () => false,
};

const demoLoadScript = {
  /*
   * This demo script shows how to call and use the utility scripts
   * loadScript() and loadCSS(). The behaviour of each script is the
   * same, only the method of attaching to the document changes.
   *
   * This function can be removed before production builds.
   */
  selectors: {
    // the selector is used to determine whether the scripts should be loaded
    target: 'body',
  },
  init: () => {
    if (modal.required()) {
      modal.load();
    }
  },
  // Search for the target in the DOM and return whether any hits were found.
  required: () => document.querySelectorAll(demoLoadScript.selectors.target).length > 0,
  // The main load functionality begins here.
  load: () => {
    // create a promise that calls one or more scripts to be loaded.
    Promise.all([
      // can be loaded via an external CDN link
      loadScript('https://cdn.jsdelivr.net/npm/a11y-dialog@7/dist/a11y-dialog.min.js'),
      // loadScript('https://cdn.jsdelivr.net/npm/body-scroll-lock@4.0.0-beta.0/lib/bodyScrollLock.min.js'),
      // or via a link to a locally hosted file
      loadScript('/js/libraries/a11y-dialog.min.js'),
      // loadScript('/js/libraries/bodyScrollLock.min.js'),
    ])
      .then(() => {
        // if the script is loaded successfully we log to the console (optional)...
        // console.log('Loaded A11y modal scripts');
        // ... and throw the custom event.
        const modalLoaded = new CustomEvent('modalScriptLoaded');
        document.dispatchEvent(modalLoaded);
      })
      .catch((/* error */) => {
        // if the script cannot be loaded, we deal with the error.
        // console.log('Could not load A11y modal scripts', error);
      });
  },
};

(() => {
  document.addEventListener('DOMContentLoaded', () => {
    /*
    * Demo of LoadScript init continues...
    * Call the target construct(s) that will use the loaded scripts.
    * At the sme time, call demoLoadscript to check whether we need to load the promise.
    * Modal.init() won't really do anything if the custom event is never triggered.
    * demoLoadScript.init() won't do anything if there's no selectors to attach to.
    */

    modal.init();
    demoLoadScript.init();

    const demo = document.createElement('div');
    demo.innerHTML = `mod(12, 5): ${mod(12, 5)}
minus(35, 10): ${minus(35, 10)}
plus(-1000, -2500): ${plus(-1000, 2500)}
item('four'): ${item('four')}
`;
    document.body.prepend(demo);
  });
})();
